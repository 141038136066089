import React, { useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const useStripeCheckout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const initiateCheckout = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-checkout-session`, {}, {
        withCredentials: true,
      });
      const { sessionId } = response.data;
      const stripe = await stripePromise;

      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          throw new Error(error.message);
        }
      } else {
        throw new Error('Stripe failed to load.');
      }
    } catch (error) {
      console.error('Error initiating checkout:', error);
      setError('Failed to initiate checkout. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return { initiateCheckout, isLoading, error };
};

const App = () => {
  const { initiateCheckout, isLoading, error } = useStripeCheckout();

  return (
    <div className="App">
      <h1>Purchase Our Product</h1>
      <div>
        <h2>One-Time Product</h2>
        <p>Buy this product for €1.00</p>
        <button onClick={initiateCheckout} disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Buy Now'}
        </button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

export default App;



